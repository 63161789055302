﻿@import "_variables.scss";
@import "responsives/_resp_common.scss";

.macro{

	&-insert{

		&-image{
			max-width:100%;

			@include max-width-xs{
				width:100%;
			}

			&-wrap{
				display:inline-block;
				width: auto;
				max-width:100%;
				padding: 0;
				margin-top:0.5rem;
				margin-bottom:1rem;
				margin-right: 1rem;
				float:left;

				&.align-right{ 
					float: right !important;
					margin-left: 1rem;
					margin-right: 0;
				}
				
				figure{
					width: auto;
					max-width:100%;
				}

				img {
					max-width: 100%;
				}	
				
				&.align-full{
					display:block;
					float:none;
					margin-left:0;
					margin-right:0;

					img{
						width:100%;
					}
				}	
				
				@include max-width-xs {
					display: block;
					float: none;
					width:100%;
					text-align: center;

					figure{
						display:block;
						width:100%;
					}

					&:after,
					&:before{
						content:'';
					}
				}	

				&.lined{
					border-top: 4px solid $color-accent;
				}
			}

			&-caption{
				font-size: 0.6667rem;
				font-weight:400;
				padding:0.25rem;
				color: $color-accent;

				.lined &{
					border-bottom: 1px solid $color-accent;
				}
			}
		}
	}
}

