﻿@import "variables";
@import "responsives/_resp_common.scss";

.page-top-band{
		background: url(/Static/gfx/metal-mesh-grid-33x40-5pst.png) repeat top left $color-black;
		color: #fff;
}

svg{

	&.to-logo{

		&-red-black{
			.element-logo{
				fill: yellow !important;
			}
		}
	}
}

.page-head{

	.top-wrap{
		display: flex;
		flex-direction:row;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: nowrap;
		padding: 1rem 0;

		& > .logo-wrap{
			flex: 1 1 auto;
			width: 30%;
			min-width: 190px;
			max-width: calc(100% - 2rem);

			img{
				max-width: 220px;
			}

			& > .slogan{
				max-width: 100%;
				font-style: italic;
				letter-spacing: 0.06em;
			}

			@include max-width-xs{
				margin-bottom:0;
				width:100%;
			}
		}

		& > .top-nav-wrap{
			flex: 1 1 auto;
			width: 70%; 
			text-align: right;
		}

		@include max-width-xs{
			position:fixed;
			top:0;
			left:0;
			padding: 1rem 1rem 0.25rem 1rem;
			width:100%;
			max-height: 60px;
			min-height: 60px;
			overflow: hidden;
			z-index:2000;
			background: $color-black;
		}
	}


}

.hero-wrap{
	display: block;
	width: 100%;
	max-width: 1920px;
	margin:0 auto;

	@include max-width-xs{
		position:relative;
		top:60px;
	}

	.hero-inner-wrap{
		width: 100%;
		height:400px;
		display: block;
		background: rgba(175, 36, 52, 0.05);
		transition: all 0.3s ease-in-out;
		background-position: center top;
		background-size: cover;

		.hero-text-wrap {
			padding: 1rem;
			margin: 3rem 0 0 0;
			width: 30%;
			background: rgba(0,0,0,0.8);
			box-shadow: 10px 10px 7px rgba(0,0,0,0.3);
		}

		@include max-width-md {
			height: 35vw;
			min-height: 300px;
			background-position: 60% top;
		}

		@include max-width-sm{
			height: 30vw;
			min-height: 250px;
			background-position: 70% top;
		}

		@include max-width-xs{
			height: 25vw;
			min-height: 200px;
			background-position: 80% top;
		}




	}
}

