﻿@import "../_variables";


/* /// MEDIA QUERY MIXINS */
/*     Can be used whereever in other scss-files when a media query is needed */
/*     Or here as sentralized general media queries */

@mixin min-width-lg {
	// >= lg
	// overrides any defined earlier
	// belongs directly below normal cascade (not media query)
	@media screen and (min-width: #{$screen-lg-min}){
		@content;
	}
}

@mixin extreme-width-limit {
	// > screen-width limit
	// overrides any defined earlier
	// belongs after normal cascade (not media query) 
	// and between min-width-lg and max-width-lg in cascade
	@media screen and (min-width: #{$screen-max-width}){
		@content;
	}
}


@mixin max-width-lg {
	// < lg and >= md
	// overrides any defined earlier
	// belongs between min-width-lg and max-width-md in cascade
	@media screen and (max-width: #{$screen-lg-min}){
		@content;
	}
}

@mixin max-width-md {
	// < md and >= sm
	// overrides any defined earlier
	// belongs between max-width-lg and max-width-sm in cascade
	@media screen and (max-width: #{$screen-md-max}){
		@content;
	}
}

@mixin max-width-sm {
	// < sm and  >= xs 
	// overrides any defined earlier
	// belongs between max-width-md and max-width-xs in cascade
	@media screen and (max-width: #{$screen-sm-max}){
		@content;
	}
}

@mixin max-width-xs {
	// < xs and >= xxs 
	// overrides any defined earlier
	// belongs between max-width-sm and max-width-xxs in cascade
	@media screen and (max-width: #{$screen-xs-max}){
		@content;
	}
}

@mixin max-width-xxs {
	// < xxs 
	// overrides anything defined earlier
	// belongs at end of cascade
	@media screen and (max-width: #{$screen-xxs-max}){
		@content;
	}
}

