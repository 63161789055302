﻿@import "variables";
@import "responsives/_resp_common.scss";

[data-page-type="articlepage"]{

	.content{

		&-wrap{

			&-aside,
			&-main{
				/*border: 1px dashed rgba(0,0,0,0.1);*/
			}

			&-aside{
				position: relative;
				
				&.padded{
					padding-top: $padding-aside-top-articles;

					&:before{
						position: absolute;
						left:0;
						top:$padding-aside-top;
						content: '';
						display:block;
						height: calc(100% - #{$padding-aside-top});
						width: 3px;
						border-left: 3px dashed $color-accent;
						transition: all 1s linear;

						@include max-width-xs{
							opacity: 0;
						}
					}

					@include max-width-xs{
						padding-top: initial;
					}
				}
			}
		}
	}
}