﻿@import "variables";
@import "responsives/_resp_common.scss";

[data-page-type="sectionpage"]{

	.content{

		&-wrap{

			&-aside,
			&-main{
				/*border: 1px dashed rgba(0,0,0,0.1);*/
			}

			&-aside{
				position: relative;
				
				&.padded{
					padding-top: $padding-aside-top;

					&:before{
						position: absolute;
						left:0;
						top:$padding-aside-top;
						content: '';
						display:block;
						height: calc(100% - #{$padding-aside-top});
						width: 3px;
						border-left: 3px dashed $color-accent;
						transition: all 1s linear;

						@include max-width-xs{
							opacity: 0;
						}
					}

					@include max-width-xs{
						padding-top: initial;
					}
				}
			}
		}
	}
}

.sub-nav{


	&-section{

		h3{
			position:relative;

			&:first-of-type{
				display:block;
				padding: 0.25rem 2rem 0.25rem 0;
				margin-top: -0.4rem;
			}

			.navbar-toggle{
				display: none;

				@include max-width-xs{
					position:absolute;
					display: inline-block !important;
					top:50%;
					transform: translateY(-50%);
					right:-0.5rem;
					font-size: 2rem;
					margin:0;
					padding:0.5rem;
				}
			}
		}

		ul.nav{

			& > li{

				& > a{

					&.item-link{
						color: $color-bright;
						background: $color-accent;
						border: 1px solid $color-accent;
						border-bottom: 1px dashed rgba(255,255,255,0.2);
						font-weight: 400;
						padding-left: 1rem;
						padding-right: 1rem;
						transition: all 0.3s ease-in-out;

						&:hover{
							background: $color-bright;
							color: $color-accent-dark;
							border-style: dashed;
							border-color: $color-accent;
							border-top: 1px solid transparent;
							border-bottom:1px solid transparent; 
						}
					}
				}

				&:first-of-type{
					
					& > a{

						&.item-link{
							border-radius: 0.5rem 0.5rem 0 0;
							border-top:1px solid $color-accent;

							&:hover{
								border-top: 1px dashed $color-accent;
							}
						}
					}
				}

				&:last-of-type{

					& > a{

						&.item-link{
							border-radius: 0 0 0.5rem 0.5rem;
							border-bottom: 1px solid $color-accent;

							&:hover{
								border-bottom-style: dashed;
							}
						}
					}
				}

				&.active{

					& > a{

						&.item-link{
							background: rgba(255,255,255,0.1);
							color: $color-accent;
							font-weight: 600;
						}
					}
				}

			}
		}
	}

	&-list{
		padding:0;
		margin:0;
		list-style-type:none;
		border-left: 1px solid $color-accent;
		border-right: 1px solid $color-accent;

		& &{
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;

			li.sub-menu-item{
				padding-left: 0.5em;
			}
		}

		& > li{
		
			&.sub-menu-item{
				padding-left: 0;
				background: $color-accent-05;

				& > a{

					&.item-link{
						position: relative;
						display:block;
						color: $color-accent;
						background: transparent;
						border: 1px solid transparent;
						border-bottom: 1px dashed rgba(0,0,0,0.2);
						font-weight: 400;
						padding: 0.5rem 1rem 0.5rem 2rem;
						transition: all 0.3s ease-in-out;

						& > i{
							position: absolute;
							display: inline-block;
							top: 50%;
							transform: translateY(-50%); 
							left:0.7em;
						}

						&:hover{
							background: $color-accent-10;
							color: $color-accent-dark;
							border: 1px solid transparent;
							border-bottom: 1px dashed $color-accent;
						}
					}
				}

				&.active{

					& > a{

						&.item-link{
							color: $color-black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}