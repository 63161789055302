﻿@import "../_variables";

@media (max-width: $screen-sm-max){
	.hero-wrap{

		.hero-inner-wrap{

			.hero-text-wrap{
				width: 40%;
			}
		}
	}
}

@media (max-width: $screen-xs-max){

	.hero-wrap{

		.hero-inner-wrap{

			.hero-text-wrap{
				width: 100%;
			}
		}
	}
}

