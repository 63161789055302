﻿@import "_variables.scss";
@import "responsives/_responsive.scss";
.page{

	&-footer{

		&-wrap{
			flex: 1 1 auto;
			background: $color-xlight;
			min-height: 150px;
		}

		&-content{
			padding: 3rem 1rem;

		}
	}
}

.footer{

	&-address{

		&-wrap{
			color: $color-dark;

			p{
				color: $color-black;
				font-weight:400;
			}
		}
	}
}