﻿@import "variables";
@import "responsives/_resp_common.scss";

html,
body,
:root{
	font-size: 20px;
	padding:0; 
	margin:0;

	@include max-width-lg{
		font-size:18px;
	}

	@include max-width-xs{
		font-size:16px;
	}
}

html {
	height: 100%;
}


body {
	font-family: $font-family-normal;
	font-weight: 100;
	background: white;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
}

a{
	text-decoration-skip: ink;

	&.block-link{
		text-decoration: none;
		color: inherit;

		h3, p, div, span, i{
			
		}

		&:hover{
			text-decoration: none;
		}
	}
}

.marker{

	&.red{
		color: $color-accent;
	}

	&.bold{
		font-weight: 600;
	}
}

h{
	&1,&2,&3,&4,&5,&6{
		font-family: $font-family-headlines;
		font-weight: 600;
		margin: 1.5rem 0 0.5rem 0;
		color: $color-accent;

		&:first-of-type{
			margin-top:0;
		}

		.lead + &{
			margin-top: 3rem;
		}
	}

	&1{
		font-size: 2.2rem;
		margin-top: 1.5rem;

		@include max-width-sm{
			font-size: 2rem;
		}

		@include max-width-xs{
			font-size: 1.8rem;
		}
	}

	&2{
		font-size: 1.9rem;
	}

	&3{
		font-size: 1.6rem;
	}

	&4{
		font-size: 1.3rem;
	}

	&5{
		font-size: 1.1rem;
	}

	&6 {
		font-size: 1rem;
	}
}

p{
	margin: 0 0 0.5rem 0;
}

img{
	max-width: 100%;
}

.cta{
	
	&-accent{
		background: $color-accent;
		padding:1rem;
		text-align: center;
		border-radius: 0.25rem;
		transition: all 0.2s ease-in-out;

		& > h3{
			color: $color-bright;
			margin:0;
		}

		& > p{
			color: $color-bright;
			margin: 0;
		}
	}
}

.btn{

	&-primary{
		background: $color-accent;
		color: $color-bright;
		border: 2px solid transparent;
		transition: all 0.2s ease-in-out;
		text-decoration: none;

		&:hover{
			background: $color-black;
			color: $color-bright;
			text-decoration: underline;
		}

		h1 &,
		h2 &,
		h3 &,
		h4 &,
		h5 &{
			margin-top:0.5em;
			font-size: 1.8rem;
			display:block;
			white-space: inherit;
		}
	}

	&-link{
		padding:0;
		margin:0;
		display: inline-block;
		color: inherit;
	}
}

.svg-icon-wrap{

	& > svg{
		fill: currentColor;
		height: 1.2em;
		vertical-align:sub;
	}
}

.lead{
	font-weight:400;
	font-size: 1.15rem;
}

.page {

	&-body {
	
		&-text {

			ul {
				margin-top:1.5rem;
				margin-bottom:1.5rem;

				li{
					margin-bottom:0.5rem;
				}
			}
		}
	}

	&-top{

		&-band{


			
		}
	}

	&-head{
			/*@include max-width-xs{
				position:fixed;
				top:0;
				left:0;
				width:100%;
				z-index:2000;
				background: $color-black;
			}*/

			.svg-icon-wrap{

				svg{
					fill: currentColor;
					height: 1.2em;
					vertical-align:sub;
				}
			}
	}

	&-content{

		&-wrap{
			
			@include max-width-xs{
				margin-top: 60px;
			}
		}
	}
}

.content{

	&-band{
		position: relative;
		margin:0;
		padding: 3rem 0;

		@include max-width-sm{
			padding:2.5rem 0;
		}

		@include max-width-xs {
			padding:1.5rem 0;
		}
	}

	&-wrap{

		&-main,
		&-aside {
			/*flex: 1 1 auto;*/
		}

		&-main{
			position:relative;
			width: 69.9999%;
			flex: 1 1 auto;

			&.padded{
				padding-right: $content-padding-width;

				@include max-width-xs{
					padding-right:initial;
				}
			}

			& + .content-wrap-aside{
				width: 30%;

				@include max-width-md{
					width: 40%;
				}

				@include max-width-sm{
					width: 50%;
				}

				@include max-width-xs{
					width: 100%;
					order: -1;
				}
			}

			@include max-width-md{
				width: 59.9999%;
			}

			@include max-width-sm {
				width: 49.9999%;
			}

			@include max-width-xs{
				width: 100%;
			}
		}

		&-aside{
			position:relative;
			flex: 0 1 auto;

			&.padded{
				padding-left: $content-padding-width;

				@include max-width-xs{
					padding-left:initial;
					padding-right: $content-padding-width;
				}
			}
		}
	}

	&-section{

		h1{

			&:first-of-type{
				margin-top:0;
			}
		}

		&-wrap{
			display: flex;
			flex-flow:row nowrap;

			@include max-width-xs{
				flex-flow: column wrap;
			}
		}
	}

	&-aside{
		padding: 1.5rem 0;
	}
}

.bordered{

	&-topbottom{
		border-top: 1px solid $color-accent;
		border-bottom: 1px solid $color-accent;
	}
}

.text{

	&-small{
		font-size: 0.8888rem;
		line-height:1rem;
	}

	&.large {
		font-size: 1.2rem;
		line-height: 1.5rem;
	}

	&.huge{
		font-size: 1.6rem;
		line-height: 1.9rem;
	}
}

.quote{

	&-large{
		color: $color-accent;
		padding: 1rem 0;
		font-size: 1.2rem;
		line-height: 1.5rem;
		font-weight:200;
		font-style:italic;
	}
}

.tint{

	&-light {
		background: rgba(0,0,0,0.05);
	}

	&-black {
		background: $color-black;
		color: #fff;

		&-meshed {
			background: url(/Static/gfx/metal-mesh-grid-33x40-5pst.png) repeat top left $color-black;
			color: #fff;
		}
	}
}

.label{

	&-debug{
		display: inline-block;
		position: absolute;
		top:0;
		left:0.5rem;
		font-size: 0.6rem;
		font-weight:600;
		background: $color-accent-10;
		color: $color-black;
		padding: 0.5em;
		text-transform:uppercase;
		border-radius: 0.5em;
		box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
		transform: translateY(-50%) translateX(-0.5rem);

		.container &{
			
		}
	}
}



.top-nav {
	text-align: right;

	&-cultures {
		display: inline-flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: center;
		padding: 0;
		margin: 0;
		list-style-type: none;

		& > .culture {
			&-item {
				padding: 0 0.25em;
				opacity: 0.5;
				transition: all 0.5s ease-in-out;

				&:hover{
					opacity:0.8;
				}

				&.active{
					opacity: 1;
					
					& > .culture-item-link{
						cursor: default;
					}
				}
			}
		}
	}

	.cultures-label {
		font-size: 0.8rem;
		color: $color-dark-gray;
	}

	& > .nav {
		margin-top: -0.25rem;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-start;


		& > li {
			display: inline-block;
			float: right;
			padding: 0 0.5rem 0.25rem 0;
			background: transparent;
			white-space: nowrap;
			transition: all 0.3s ease-in-out;

			&:not(:first-of-type) {

				&:before {
					display: inline-block;
					margin-right: 0.25em;
					font-size: 0.8rem;
					content: '|';
					color: rgba(255,255,255,0.1);
				}
			}

			& > a {
				position: relative;
				display: inline-block;
				color: $color-gray;
				padding: 0;
				font-weight: 500;
				font-size: 0.8rem;
				border-bottom: 2px solid transparent;
				transition: all 0.3s ease-in-out;

				&:hover {
					color: $color-bright;
					background: transparent;
					border-bottom: 2px dashed $color-accent;
				}
			}

			form {
				position: relative;
				display: inline-block;
				margin: 0;
				padding: 0;

				.btn.btn-link.login {
					font-size: 0.8rem;
					line-height: 1;
					display: inline-block;
					margin: 0 0 4px 0;
					padding: 0;
				}
			}


			&:hover {

				& > a {
					color: $color-bright;
					background: transparent;
					border-bottom: 2px dashed $color-accent;
				}
			}
		}
	}
}

.top-wrap{

	& > .navbar-toggle{
		position:absolute;
		top:12px;
		right:0.5rem;
		float: none;
		margin:0;
		padding:0;
		font-size:1.7rem;
	}


}

.main-nav-wrap{
	position: relative;
}

.main{

	&-wrap{
		display: flex;
		flex-flow: column nowrap;
		justify-content:space-between;

		@include max-width-xs{
			padding-top: 60px;
		}
	}
}

.main-nav{
	position: relative;
	z-index: 1030;
	background: $color-black;
	margin: 0 -0.8rem 0 -0.8rem;
	transform: translateY(50%);

	@include max-width-xs{
		transform: none;
	}

	.nav{
		padding-left: 0;
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items:flex-start;

		& > li{
			display: flex;
			flex-direction: column;
			justify-content:space-between;
			flex: 0 0 auto;
			padding:0 0.5rem 0 0;
			background: transparent;
			white-space:nowrap;
			transition: all 0.3s ease-in-out;
			border:none;

			& > a{
				flex: 1 0 auto;
				display: block;
				color: $color-bright;
				height: 100%;
				border-bottom: 2px dashed transparent;
				transition: all 0.2s linear;

				&:hover,
				&:active {
					background: $color-accent-50;
					border-bottom: 2px solid $color-accent-bright;
				}

			}
		}
	}

	.navbar-collapse{

		.nav{

			& > li{

				& > a{

					&:hover{
						background: $color-bright;
						color: $color-accent;

						.file-icon{
							color: $color-accent;
							fill: currentColor;
						}
					}
				}
			}
		}

		@include max-width-xs{
			position: fixed;
			top:60px;
			left:0;
			width:100vw;
			padding:1rem 0 4rem 0;
			margin:0;
			border:none;
			box-shadow:none;
			transform: none;
			height: calc(100vh - 60px);
			background: $color-black;
			/*transition: background 0.2s linear;*/

			&.in{
				background: $color-accent;
				height: calc(100vh - 60px) !important;
				overflow:auto !important;
			}

			.nav{
				flex-direction: column !important;

				& > li{
					display:block;
					flex: 1 0 auto;
					width: 100%;
					padding:0;

					& > a{
						position:relative;
						display:inline-block;
						width: 100%;
						padding: 1rem;
						font-size: 1.5rem;
						line-height: 1.2em;

						& > .file-icon{
							position:relative;
							top: 3px;

							&.svg{
								position:relative;
								width:1.2em;
								height: 1.2em;

								& > svg{
									width: 1.2em;
									height: auto;

								}
							}
						}
					}
				}
			}
		}
	}


}

.shortcuts-wrap {
	margin: 0;

	.shortcuts-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: stretch;

		@include max-width-xxs{
			flex-wrap: wrap;
		}

		& > li {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;
			align-items: stretch;
			box-sizing: border-box;
			flex: 1 1 auto;
			width: 23%;
			/*min-width: 280px;*/
			padding: 0;
			text-align: center;

			@include max-width-xxs{
				width:50%;
			}

			& > a {

				&.block-link {
					flex: 1 1 auto;
					padding: 1rem;
					width: 100%;
					height: 100%;
					transition: all 0.3s linear;
					border: 1px dashed transparent;

					h3 {
						font-size: 1.3rem;
						color: $color-accent;
						transition: all 0.3s linear;
						text-decoration-color: $color-accent;
						margin-top: 0.5rem;
					}

					.lead-text{
						font-size: 0.9rem;
					}

					&:hover {
						background: $color-accent-05;
						border: 1px dashed $color-accent-50;

						h3 {
							color: $color-black;
							text-decoration: underline;
							-moz-text-decoration-line: underline;
							text-decoration-line: underline;
							-moz-text-decoration-style: solid;
							text-decoration-style: solid;
							-webkit-text-decoration-skip: ink; /* unstandard for safari */
							text-decoration-skip: ink;
							-moz-text-decoration-color: $color-accent;
							text-decoration-color: $color-accent;
						}

						& > .icon-image-wrap{

							& > .svg-icon-wrap{

								& > svg{
									fill: $color-black;
								}
							}
						}
					}

					& > .icon-image-wrap{
						height:4em;

						& > .icon-image{
							max-height: 80px;
						}

						& > .svg-icon-wrap {
							width: 100%;

							& > svg{
								max-height: 80px;
								transition: all 0.3s linear;
								height: 4rem;
							}
						}
					}

					.tint-black &,
					.tint-black-meshed &{

						&:hover{

							h3{
								color: $color-bright;
							}

							& > .icon-image-wrap{

								& > .svg-icon-wrap{

									& > svg{
										fill: $color-bright;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.icon-image-wrap{

	& > .icon-image{
		max-width: 100%;
	}

	& > .svg-icon-wrap{
		
		& > svg{
			max-width: 100%;
			fill: $color-accent;
		}
	}
}

.login-overlay__background-image{
	opacity: 0.25 !important;
}

.breadcrumbs {
	margin-bottom: 1rem;

	&-wrap {
		margin-top: -1.5rem;
	}

	.nav {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-end;

		& > li {
			position: relative;
			/*			float: left;*/
			padding-right: 0.75rem;
			padding-left: 0.25rem;


			&:after {
				position: absolute;
				content: '>';
				top: 0;
				right: 0;
				font-size: 0.7rem;
				line-height: 1.2rem;
			}

			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {

				&:after {
					content: '';
				}
			}
		}
	}

	.breadcrumb {

		&-item {
			padding: 0;
			font-size: 0.8rem;
			font-weight: 400;
			color: $color-black;
			text-decoration: underline;

			&:hover {
				background: transparent;
			}

			&.current {
				color: $color-accent-bright;
				text-decoration: none;
				cursor: default;
				display: inline-flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;

				&:hover {
					background: transparent;
				}

				.breadcrumb-item-lang-flag {
					display: inline-block;
					align-self: center;
					justify-self: center;
					border-radius: 1rem;
					line-height: 1;
					width: 1rem;
					height: 1rem;
					border: none;
					overflow: hidden;
				}
			}

			&-culturelinks {
				display: inline-flex;
				margin-left: 0.5rem;
				border-radius: 0.5rem;
				transition: all 0.5s ease-in-out;

				& > .item-link {
					width: 1rem;
					height: 1rem;
					border-radius: 0.5rem;
					/*border: 2px dotted transparent;*/
					transition: all 0.5s ease-in-out;
					display: inline-flex;
					flex-flow: row nowrap;
					justify-content:center;
					align-content:center;
					padding:0;
					margin:0 0.25rem 0 0.25rem;
					opacity: 0.5;

					&.active{
						box-shadow: 0 0 2px 0 black;
						opacity: 1;
					}

					& > .breadcrumb-item-lang-flag{
						flex: 0 0 auto;
					}
				}
			}

			&-lang {
				&-flag {
					width: 1rem;
					height: 1rem;
					float: right;
					border-radius: 0.5rem;
				}
			}
		}
	}
}

.file-icon{
	position:relative;
	display: inline-block;
	color: $color-bright;
	fill: currentColor;
	width: 1.3em;
	height: 40px;
	vertical-align: baseline;

	& > svg{
		position:absolute;
		top:50%;
		transform: translateY(-50%);
		width: 1.3em;
		line-height: 1em;
	}
}

.focus{

	&-article{

		&-wrap{
			
		}

		&-image{

			&-wrap{
				margin: 1rem 0 1rem 0;
			}
		}

		&-link{
			text-decoration:none;
			display:block;
			transition: all 0.2s linear;
			color: $color-black;
			padding: 0.25rem;
			margin:-0.25rem -0.25rem 2rem -0.25rem;
			border: 1px dotted transparent;
			transition: all 0.2s ease-in-out;

			&:hover{
				text-decoration:none;
				background: $color-accent-05;
				color: $color-accent;
				border: 1px dashed $color-accent;

				.cta-accent{
					background: $color-black;
					
					h3{
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.article{

	&-list{
		margin:0;
		padding:0;
		list-style-type:none;

		&-item{

			.list-items-in-row &{
				position: relative;
				margin-bottom: 0;

				.block-link{
					display: block;
					position:relative;
					padding: 0.25rem;
					margin:-0.25rem;
					border: 1px dotted transparent;
					text-decoration: none;
					transition: all 0.2s ease-in-out;

					&:hover{
						background: $color-accent-05;
						color: $color-accent;
						border: 1px dashed $color-accent;
						text-decoration: none;
					}
				}
			}

			&-image{
				
				&-wrap{
					& > img{
						width: 160px;
					}
				}
			}

			&-wrap{

				.list-items-in-row &{
					display:flex;
					justify-content:space-between;
					align-content:flex-start;
					flex-flow: row nowrap;
					max-width: 100%;
					border-bottom: 1px solid $color-accent;

					.article-list-item-image-wrap{
						flex:0 0 auto;
						width: 100px;
					}

					.article-list-item-text-wrap{
						flex:1 0 auto;
						width:auto;
						max-width: calc(100% - 100px);
						padding:0 0.5rem;
						

						h3{
							margin-top:0;
							margin-bottom: 0.25rem;
							font-size: 1.2rem;
						}

						.article-list-item-lead{
							font-size: 0.7778rem;
						}
					}
				}
			}

			&-lead{

			}
		}
	}
}

.list{

	&-items{

		&-in{

			&-row{

				& > h2{

					&:first-of-type{
						
					}
				}
			}
		}
	}

	&-articles{
		margin:0;
		padding:0;
		list-style-type:none;

		& > li{
			display: inline-block;
		}
	}

	&-select{
		display: flex;
		justify-content:space-between;
		flex-flow: row nowrap;

		@include max-width-sm{
			flex-flow: row wrap;
			align-content:center;
		}

		@include max-width-xs{
			flex-flow: column nowrap;
		}

		&-item{
			flex: 1 1 auto;
			width: 33%;

			img{
				width: 100%;
			}

			.text-wrap{
				padding: 0.5em 0;
				font-size: 0.7778rem;

				@include max-width-xs{
					padding: 0.5em 0;
				}
			}

			h3{
				position: relative;
				font-size: 1.2rem;
				height: 2.1em;

				& > span{
					position: absolute;
					display: inline-block;
					bottom:0;
					
				}
				vertical-align: bottom;
			}

			.block-link{
				display: block;
				border: 1px solid transparent;
				padding:0.25rem;
				transition: all 0.2s ease-in-out;

				&:hover{
					background: $color-accent-05;
					color: $color-accent;
					border: 1px dashed $color-accent;
				}
			}

			@include max-width-sm{
				width: 50%;
			}

			@include max-width-xs{
				width: 100%;
			}
		}
	}
}

.padding{

	&-left{

		&-s{
			padding-left: 1rem;
		}
		&-m{
			padding-left: 2rem;
		}
	}
}
	
